import moment from "moment";
const baseURL = process.env.VUE_APP_BASE_URL;

export function ReplaceSmartTagsWithContent(app, user, rawText = "") {
  const club = app.$store.getters.club;
  const tags = rawText?.match(/([[])([^[\]]*)([\]])/gi) ?? [];
  const uniqueTags = [...new Set(tags)];

  let text = rawText;

  uniqueTags.forEach((tag) => {
    let defaultValue = "";
    let tagName = tag.replaceAll(/[[\]]/gi, "");

    if (tag.includes("||")) {
      defaultValue = tagName.split("||")[1];
      tagName = tagName.split("||")[0].toLowerCase();
    }

    let value = defaultValue;

    switch (tagName) {
      case "KLUBBA_URL":
        value = `${baseURL}/${club.id}` ?? defaultValue;
        break;

      case "SUBSCRIBER_FIRSTNAME":
        value = user.first_name ?? defaultValue;
        break;

      case "SUBSCRIBER_PRODUCT":
        value = user?.subscription?.product_name ?? "(no product)";
        break;

      case "SUBSCRIPTION_BILLING_DATE":
        value = user?.subscription?.period?.start
          ? moment(user?.subscription?.period?.start * 1000).format("DD/MM/YYYY")
          : "(no billing date)";
        break;

      case "SUBSCRIPTION_LAST_BILLING_DATE":
        value = user?.subscription?.last_payment
          ? moment(user?.subscription?.last_payment * 1000).format("DD/MM/YYYY")
          : "(no last billing date)";
        break;

      case "SUBSCRIPTION_EXPIRY_DATE":
        value = user?.subscription?.period?.end
          ? moment(user?.subscription?.period?.end * 1000).format("DD/MM/YYYY")
          : "(no expiry)";
        break;

      case "SUBSCRIPTION_AMOUNT":
        value = user?.subscription?.price ? `A$${user?.subscription?.price}` : "(no amount)";
        break;

      case "TOTAL_BILLINGS":
        value = user?.subscription?.total ? `A$${user?.subscription?.total}` : "(no total amount)";
        break;

      case "CLUB_NAME":
        value = club.name ?? defaultValue;
        break;

      case "DAYS_UNTIL_EXPIRY":
        value = user?.subscription?.period?.end
          ? moment().to(moment(user?.subscription?.period?.end * 1000))
          : "(no expiry)";
        break;

      default:
        value = defaultValue;
        break;
    }

    text = text.replaceAll(tag, value);
  });

  return text;
}
